<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{  $t('germplasm.characterization_form') }}</h4>
            </template>
            <template v-slot:headerAction>
                    <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.export_pdf') }}
                    </b-button>
            </template>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id= "6">
                  {{ $t('germplasm.characterization_form') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                   <b-row>
                       <b-col class="col-md-4 text-black font-weight-bold ml-4">{{ $t('germplasm.discriptor') }}</b-col>
                        <b-col class="col-md-4 text-black font-weight-bold">{{ $t('germplasm.code_guide') }} </b-col>
                        <b-col class="col-md-3 text-black font-weight-bold text-center">{{ $t('germplasm.groth_stage') }}</b-col>
                   </b-row>
                   <br><br>
                    <b-row>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(1) + '. ' + $t('germplasm.access_no') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(2) + '. ' + $t('germplasm.name') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(3) + '. ' + $t('germplasm.former_designation') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(4) + '. ' + $t('germplasm.seed_source') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(5) + '. ' + $t('germplasm.country_of_origin') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                          <b-col class="col-md-4 text-black"><div class="ml-4">{{ $n(6) + '. ' + $t('germplasm.varietal_group') }}</div></b-col><b-col class="col-md-8 text-black"> ................................ </b-col>
                      <b-col v-for="(item, index) in formData.characterization_info" :key="index" class="col-12">
                          <template>
                            <div class="" role="tablist">
                                <b-card-body variant="info" class="text-left capitalize text-black font-weight-bold">{{ $i18n.locale === 'en' ? item.descriptor_label_name : item.descriptor_label_name_bn }}</b-card-body>
                                <b-card-body class="text-black">
                                    <b-row v-for="(txt, index1) in item.descriptor_label_info" :key="index1">
                                        <b-col class="col-md-4 capitalize">{{ $n(txt.descriptor_heading_sorting_order) + '. ' }}{{ $i18n.locale === 'en' ? txt.descriptor_heading_name : txt.descriptor_heading_name_bn }}</b-col>
                                        <b-col class="col-md-5">
                                            <div v-for="(list, index2) in txt.descriptor_heading_info" :key="index2">
                                                <div v-if="list.code_guide_type ===2">{{ $n(list.code_guide_sorting_order) + '.   ......................................................' }}</div>
                                            </div>
                                            <div v-for="(list, index2) in txt.descriptor_heading_info" :key="index2">
                                                <div v-if="list.code_guide_type ===1">{{ $n(list.code_guide_sorting_order) + '. ' }}{{ $i18n.locale === 'en' ? list.code_guide_name : list.code_guide_name_bn }}</div>
                                            </div>
                                        </b-col>
                                        <b-col class="col-md-3">{{ $i18n.locale === 'en' ? txt.descriptor_heading_description : txt.descriptor_heading_description_bn }}</b-col>
                                    </b-row>
                                </b-card-body>
                            </div>
                          </template>
                      </b-col>
                    </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, characterizationShow } from '../../api/routes'
import ExportPdf from './export-pdf_details'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    if (this.$route.query.id) {
        this.getFormData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        crop_name_id: 0,
        accession_number: '',
        characterization_info: []
      },
      inputList: [],
      optionObj: {},
      optionObjBn: {},
      blankObj: {},
      characterizationDetails: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  methods: {
      getBlankOrder (arr) {
          const codeGuide = arr.find(item => item.code_guide_type === 2)
          return typeof codeGuide !== 'undefined' ? codeGuide.code_guide_sorting_order : 2
      },
    async getFormData () {
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, characterizationShow + this.$route.query.id)
        this.formData.id = result.data.id
        this.formData.accession_number = result.data.accession_number
        this.formData.crop_name_id = result.data.crop_name_id
        this.characterizationDetails = result.data.descriptor_labels
      this.getCollectionData()
    },
    getCollectionData () {
        const details = this.characterizationDetails
        details.forEach(item => {
            const levelObj = {
                descriptor_label_id: item.id,
                descriptor_label_name: item.heading_name,
                descriptor_label_name_bn: item.heading_name_bn,
                descriptor_label_sorting_order: item.sorting_order,
                descriptor_label_info: []
            }
            item.descriptor_heading_details.forEach(list => {
                const characterDetail = this.characterizationDetails.find(doc => doc.descriptor_label_id === item.id && doc.descriptor_heading_id === list.id)
                const headingObj = {
                    descriptor_heading_id: list.id,
                    descriptor_heading_name: list.heading_name,
                    descriptor_heading_name_bn: list.heading_name_bn,
                    descriptor_heading_sorting_order: list.heading_sl,
                    descriptor_heading_description: list.description,
                    descriptor_heading_description_bn: list.description_bn,
                    descriptor_heading_info: [],
                    code_guide_mcq_value: typeof characterDetail !== 'undefined' ? characterDetail.code_guide_mcq_value : [],
                    code_guide_blank_value: typeof characterDetail !== 'undefined' ? characterDetail.code_guide_blank_value : ''
                }
                const newList = []
                const newListBn = []
                const newList2 = []
                list.code_guide_details.forEach(doc => {
                    const codeObj = {
                    code_guide_id: doc.id,
                    code_guide_name: doc.code_guide_name,
                    code_guide_name_bn: doc.code_guide_name_bn,
                    code_guide_sorting_order: doc.code_guide_sl,
                    code_guide_type: doc.type_id
                    }
                    if (doc.type_id === 1) {
                        const obj = {
                            value: doc.id,
                            text: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name,
                            text_en: doc.code_guide_sl + '. ' + doc.code_guide_name,
                            text_bn: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name_bn
                        }
                        const objBn = {
                            value: doc.id,
                            text: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name_bn,
                            text_en: doc.code_guide_sl + '. ' + doc.code_guide_name,
                            text_bn: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name_bn
                        }
                        newList.push(obj)
                        newListBn.push(objBn)
                    }
                    if (doc.type_id === 2) {
                        const obj = {
                            value: doc.id,
                            text: this.$i18n.locale === 'en' ? this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name : this.$n(doc.code_guide_sl) + doc.code_guide_name_bn
                        }
                        newList2.push(obj)
                    }
                    headingObj.descriptor_heading_info.push(codeObj)
                })
                this.optionObj[list.id] = newList
                this.optionObjBn[list.id] = newListBn
                this.blankObj[list.id] = newList2
                levelObj.descriptor_label_info.push(headingObj)
            })
            this.formData.characterization_info.push(levelObj)
        })
    },
    pdfExport () {
        const reportTitle = this.$t('germplasm.characterization_form')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/germplasm/config/report-heading/detail', 6, reportTitle, this.formData, this, this.characterizationDetails, this.optionObj, this.optionObjBn)
    }
  }
}
</script>
