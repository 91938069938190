import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, details, optionObj, optionObjBn) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })

        const header = [
            [
              { text: vm.$t('germplasm.discriptor'), style: 'th', alignment: 'left' },
              { text: vm.$t('germplasm.code_guide'), style: 'th', alignment: 'center' },
              { text: vm.$t('germplasm.groth_stage'), style: 'th', alignment: 'center' }
            ]
          ]
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['30%', '40%', '30%'],
              body: header
            },
            layout: {
                hLineWidth: function (i, node) {
                  return 0
                },
                vLineWidth: function (i, node) {
                  return 0
                }
              }
          })
          pdfContent.push({ text: '', style: 'header2', alignment: 'center', decoration: 'underline' })

        const allRows = [
            [
              { text: vm.$n(1) + '. ' + vm.$t('germplasm.access_no'), style: 'td', alignment: 'left' },
              { text: '................................ ', style: 'td', alignment: 'left' },
              { text: '', style: 'td', alignment: 'right' }
            ]
          ]
          allRows.push(
            [
                { text: vm.$n(2) + '. ' + vm.$t('germplasm.name'), style: 'td', alignment: 'left' },
                { text: '................................ ', style: 'td', alignment: 'left' },
                { text: '', style: 'td', alignment: 'right' }
              ]
            )
        allRows.push(
            [
                { text: vm.$n(3) + '. ' + vm.$t('germplasm.former_designation'), style: 'td', alignment: 'left' },
                { text: '................................ ', style: 'td', alignment: 'left' },
                { text: '', style: 'td', alignment: 'right' }
              ]
            )
            allRows.push(
                [
                    { text: vm.$n(4) + '. ' + vm.$t('germplasm.seed_source'), style: 'td', alignment: 'left' },
                    { text: '................................ ', style: 'td', alignment: 'left' },
                    { text: '', style: 'td', alignment: 'right' }
                  ]
                )
            allRows.push(
                [
                    { text: vm.$n(4) + '. ' + vm.$t('germplasm.country_of_origin'), style: 'td', alignment: 'left' },
                    { text: '................................ ', style: 'td', alignment: 'left' },
                    { text: '', style: 'td', alignment: 'right' }
                  ]
                )
                allRows.push(
                    [
                        { text: vm.$n(5) + '. ' + vm.$t('germplasm.varietal_group'), style: 'td', alignment: 'left' },
                        { text: '................................ ', style: 'td', alignment: 'left' },
                        { text: '', style: 'td', alignment: 'right' }
                      ]
                    )
          data.characterization_info.map((details, index) => {
            allRows.push(
            [
              { text: (i18n.locale === 'bn') ? details.descriptor_label_name_bn : details.descriptor_label_name, style: 'th', alignment: 'left' },
              { text: '', style: 'td', alignment: 'left' },
              { text: '', style: 'td', alignment: 'left' }
            ]
            )
            details.descriptor_label_info.map((level, index1) => {
                let codeDetail = ''
                let codeDetailBn = ''
                level.descriptor_heading_info.map((list, index2) => {
                    if (list.code_guide_type === 2) {
                        codeDetail = vm.$n(list.code_guide_sorting_order) + '. ' + '...............................'
                        codeDetailBn = vm.$n(list.code_guide_sorting_order) + '. ' + '...............................'
                    }
                })
                optionObj[level.descriptor_heading_id].map((item, index3) => {
                    codeDetail = codeDetail + '\n' + item.text_en
                    codeDetailBn = codeDetailBn + '\n' + item.text_bn
                })
                allRows.push(
                [
                    { text: (i18n.locale === 'bn') ? vm.$n(level.descriptor_heading_sorting_order) + '. ' + level.descriptor_heading_name_bn : vm.$n(level.descriptor_heading_sorting_order) + '. ' + level.descriptor_heading_name, style: 'td', alignment: 'left' },
                    { text: (i18n.locale === 'bn') ? codeDetailBn : codeDetail, style: 'td', alignment: 'left' },
                    { text: (i18n.locale === 'bn') ? level.descriptor_heading_description_bn : level.descriptor_heading_description, style: 'td', alignment: 'left' }
                ]
                )
            })
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['30%', '40%', '30%'],
              body: allRows
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [1, 5, 1, 1]
          },
          fertilizers: {
            margin: [5, 5, 5, 5]
          },
          details: {
            margin: [5, 5, 5, 0]
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            margin: [0, -5, 0, 5],
            alignment: 'center'
          },
          fertilizer: {
              margin: [0, 1, 0, 1]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          transport: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('characterization-form')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
